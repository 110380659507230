.card {
  border: 2px solid rgb(24, 11, 21);
  border-radius: 10px;
  width: 500px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: azure;
}
.card-header {
  display: inline-block;
  margin: 10px; 
}
.card-header__name {
  font-weight: bold;
}
.card-header__place {
  color: rgb(12, 5, 5);
}

.card-header span {
  margin-left: auto;
  align-self: center;
}

.card-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.card-actions {
  padding: 10px;
}

.card-actions span {
  margin-right: 10px;
  font-size: 12px;
}
.card-actions span:last-child {
  float: right;
}

.likes {
  margin-left: 10px;
  color: gray;
  font-size: 12px;
}

.description {
  font-weight: bold;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.header {
  display: flex;
  border: 1px solid rgb(0, 7, 7);
  border-radius: 5px;
  padding: 5px;
  background-color: rgb(212, 89, 171);
}
.camera-icon {
  align-self: center;
  margin-left: auto;
  margin-right: 30px;
}
.camera-icon img {
  width: 100px;
  border: 2px solid black;
  border-radius: 10px;
  margin-top: 5px;
}
.logo img{
  width: 320px;
  height: 80px;
  margin-top: 5px;
  border: 2px solid black;
  border-radius: 10px;
  
}
body{
  background-color: aqua
}