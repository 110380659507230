
.l-page-btn{
  font-size: x-large;

 text-align: center;
  background: rgb(255, 115, 0);
  border: none;
  border-radius: 20px;
  text-align: center;
  height: 60px;
  width: 200px;
  color: blue;
  font-weight: 1000px;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-left: 600px;
  margin-bottom: 80px;
}
.l-page-img{
  height: 70px;
  width: 280px;
  align-self: center;
  margin-left: 300px;
  margin-bottom:200px;
  border: 2px solid rgb(211, 16, 16);
  border-radius: 10px;
 }

.b-img{
  width: 500px;
 height: 450px;
  align-items: left;
  margin-right: 80px;
  border: 2px solid black;
  border-radius: 15px;

}