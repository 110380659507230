.form-page{
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid rgb(225, 8, 178);
  padding: 30px;
  border-radius: 20px;
  background-color: aliceblue;
}
.form-button{
  height: 50px;
  width: 100px;
  background-color: #140f14;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: 700;
}
.form-name{
  height: 15px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid gray;
}
.form-address{
  height: 15px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid gray;
}
body{
  background-color: whitesmoke;
}